<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Finance sales reports
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\  Finance sales reports
                </div>
              </div>
              <div class="breadcrumb-right">
                <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item">
                      <a @click="exportToCsv()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-csv"></i>
                                </span>
                        <span class="navi-text">Export (CSV)</span>
                      </a>
                    </b-dropdown-text>
                  </div>
                  <!--end::Navigation-->
                </b-dropdown>

              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Transaction Id"
                      v-model="search.transaction_id"
                      outlined v-on:keyup.enter="searchReport"
                      dense
                      @input="search.transaction_id = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      ref="menuDate"
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.start_date"
                          label="View from date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.start_date"
                        outlined
                        dense
                        no-title
                        @input="menuStartDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      ref="menuDate"
                      v-model="menuEndDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.end_date"
                          label="View up to date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.end_date"
                        outlined
                        dense :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"

                        no-title
                        @input="menuEndDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete  v-model="search.month" outlined label="Months"
                             item-text="text" :items="months"
                             item-value="value"  v-on:keyup.enter="searchReport"
                             dense
                             clearable>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete  v-model="search.year" outlined label="Year"
                             item-text="year" :items="enrolment_years"
                             item-value="year"  v-on:keyup.enter="searchReport"
                             dense
                             clearable>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="2" v-if="currentUser.access_type!='score'">
                  <v-autocomplete
                      :items="scores"
                      v-on:keyup.enter="searchReport"
                      v-model="search.score_id"
                      label="SCORE"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense clearable
                  ></v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2">
                  <v-autocomplete
                      :items="purchase_types"
                      v-model="search.item_type"
                      v-on:keyup.enter="searchReport"
                      label="Purchase type"
                      item-text="text"
                      item-value="value" clearable
                      outlined
                      dense
                  ></v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="2">
                  <v-autocomplete
                      :items="payment_methods"
                      v-model="search.payment_method"
                      v-on:keyup.enter="searchReport"
                      label="Payment method"
                      item-text="text"
                      item-value="value"
                      outlined
                      dense clearable
                  ></v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="2">
                  <v-autocomplete
                      :items="received_by_types"
                      v-model="search.received_by_type"
                      v-on:keyup.enter="searchReport"
                      label="Received by type"
                      item-text="text"
                      item-value="value"
                      @change="users=[];"
                      outlined
                      dense clearable
                  ></v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="2" v-if="search.received_by_type">
                  <v-autocomplete
                    outlined
                    dense
                    :items="users"
                    item-text="display_text"
                    item-value="id"
                    v-model="search.received_by"
                    label="User"
                    :loading="userLoading"
                    clearable
                    :search-input.sync="userSearch"
                    placeholder="Search by first name, last name or email"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <strong>Search by first name, last name or email </strong>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12" md="2">

                  <v-select
                      :items="[
                          {text:'Yes',value:1},
                          {text:'No',value:0},
                       ]"
                      v-model="search.has_late_fee"
                      label="Has late fee ?"
                      item-text="text"
                      item-value="value"
                      clearable
                      v-on:keyup.enter="searchReport()"
                      @input="search.has_late_fee = $event !== null ? $event : ''"
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12" md="2">

                  <v-select
                      :items="[
                          {text:'Yes',value:1},
                          {text:'No',value:0},
                       ]"
                      v-model="search.is_administrated_by_federal"
                      label="Is administrated by federal ?"
                      item-text="text"
                      item-value="value"
                      clearable
                      v-on:keyup.enter="searchReport()"
                      @input="search.is_administrated_by_federal = $event !== null ? $event : ''"
                      outlined
                      dense
                  ></v-select>
                </v-col>


                <v-col cols="12" :md="search.received_by_type ? 10 : 12" class="text-right">
                  <v-btn @click="searchReport()" class="btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>
            <div class="table-responsive">
<!--              <h5>Finance Sales Reports</h5>-->
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table v-if="! loading" class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">	Transaction ID</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">	Enrolment key</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Exam/Course name</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Dates</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Invoice no.</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Candidate full name</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Enroller name</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Exam price </th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Has late fee</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Late fee</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Invoice total </th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">	Payment </th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">	Received by </th>

                </tr>
                </thead>
                <tbody>
                <template v-if="sale_reports.length != 0">
                  <tr v-for="(item, index) in sale_reports" :key="index">
                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                     <span style="max-width: 240px !important; white-space: pre-wrap;" class="font-weight-bolder text-dark-75" v-copy="item.transaction_id">
                        {{item.transaction_id ? item.transaction_id : '-' }}
                      </span>
                    </td>
                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <a @click="getCandidateSummary(item.exam_key)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.exam_key ? item.exam_key : '-' }}
                       </a>
                    </td>
                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="font-weight-medium ">{{item.exam_name}}</span>
                      <div>
                        <span class="badge badge-primary">
                          {{item.purchase_type}}
                        </span>
                      </div>
                    </td>
                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <div>
                        <strong>Order date: </strong>
                        <span class="font-weight-medium">{{item.placed_date}}</span>
                      </div>
                      <div>
                        <strong>
                          Paid date: 
                        </strong>
                        <span class="font-weight-medium ">{{item.paid_date}}</span>
                      </div>
                    </td>
                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="font-weight-medium ">{{ item.invoice_id }}</span>
                    </td>
                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="font-weight-medium">{{item.candidate_first_name}}</span>
                      <span class="font-weight-medium ml-1">{{item.candidate_last_name}}</span>
                    </td>
                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="font-weight-medium">{{item.accountholder_full_name}}</span>
                    </td>


                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="font-weight-medium">{{item.score_currency ? item.score_currency : '$'}}{{item.product_price}}</span>
                    </td>

                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="font-weight-medium">{{item.has_late_fee?'Yes':'No'}}</span>
                    </td>

                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="font-weight-medium">{{item.score_currency ? item.score_currency : '$'}}{{item.late_fee_amount}}</span>
                    </td>

                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <span class="font-weight-medium">{{item.score_currency ? item.score_currency : '$'}}{{item.amount_paid}}</span>
                    </td>
                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <div>
                        <strong>Payment method: </strong>
                        <span class="font-weight-medium">{{item.payment_method}}</span>
                      </div>
                      <div>
                      <div v-html="item.payment_status_badge"></div>
                      </div>
                    </td>
                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <div>
                        {{ item.received_by_full_name ?? 'NA' }}
                      </div>
                      <span class="font-weight-medium" v-if="item.received_by_type">({{item.received_by_type ? item.received_by_type : ''}})</span>
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td class="text-center" colspan="14">
                    No report available
                  </td>
                </tr>
                </tbody>
              </table>
              <b-pagination
                  :disabled="loading"
                  v-if="sale_reports.length > 0"
                  class="pull-right mt-7"
                  @input="getAllSalesReport"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <v-overlay :value="report_overlay">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
    </div>
  </v-app>
</template>
<script>
import ScoreService from "@/services/score/score/ScoreService";
import ReportService from "@/services/report/ReportService";
import SettingService from "@/services/setting/SettingService";
import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";
import AdminUserService from "@/services/admin-user/AdminUserService";

const enrolmentYear=new EnrolmentYearService();
const setting=new SettingService();
const score=new ScoreService();
const report=new ReportService();
const adminUser = new AdminUserService();

export default {
  data(){
    return{
      loading:false,
      sale_reports:[],
      page:'',
      total:'',
      perPage:'',
      search:{
        payment_method:'',
        transaction_id:'',
        start_date:'',
        end_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        score_id:'',
        item_type:'',
        year:'',
        has_late_fee:0,
        received_by: null,
        received_by_type: ''
      },
      payment_methods:[
        {text:'Paypal',value:'paypal'},
        {text:'Card',value:'card'},
        {text:'SchoolPay',value:'schoolpay'},
        {text:'Alepay',value:'alepay'},
        {text:'Other',value:'other'},
      ],
      purchase_types:[],
      enrolment_years:[],
      scores:[],
      menuStartDate:false,
      menuEndDate:false,
      currentYear:new Date().getFullYear(),
      months:[
        {value:'01',text:'January'},
        {value:'02',text:'February'},
        {value:'03',text:'March'},
        {value:'04',text:'April'},
        {value:'05',text:'May'},
        {value:'06',text:'June'},
        {value:'07',text:'July'},
        {value:'08',text:'August'},
        {value:'09',text:'September'},
        {value:'10',text:'October'},
        {value:'11',text:'November'},
        {value:'12',text:'December'},
      ],
      report_overlay:false,
      received_by_types:[
        {
          text: 'Local',
          value: 'local'
        },
        {
          text: 'Federal credit card',
          value: 'federal_credit_card'
        },
        {
          text: 'Federal paypal',
          value: 'federal_paypal'
        }
      ],
      users: [],
      userSearch: null,
      userLoading: false
    }
  },
  methods:{
    getAllSalesReport(){
      this.loading=true;
        report
            .getSalesReports(this.search,this.page)
            .then(response => {
             this.sale_reports=response.data.data;
              this.page = response.data.meta.current_page;
              this.total = response.data.meta.total;
              this.perPage = response.data.meta.per_page;
            })
            .catch((err) => {

            })
            .finally(()=>{
              this.loading=false;
            });
    },
    getAllScore(){
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    getAllPurchaseTypes(){
      setting
          .getPurchaseTypes()
          .then(response=>{
            for(let key in response.data.purchase_types){
              this.purchase_types.push({value:key, text:response.data.purchase_types[key]})
            }
          })
    },
    searchReport(){
      this.page=1;
      this.getAllSalesReport();
    },
    exportToCsv(){
      this.report_overlay=true;
      report
          .exportSalesToCsv(this.search)
          .then(response => {
            this.$snotify.success('Export added to queue. You will receive an email upon completion');
          })
          .catch((err) => {
          })
          .finally(()=>{
            this.report_overlay=false;
          });
    },
    getAllEnrolmentYears() {
      enrolmentYear
          .all()
          .then(response =>{
            this.enrolment_years=response.data;
            
            for (let i = 0; i < this.enrolment_years.length; i++) {
              if (this.enrolment_years[i].year === this.currentYear.toString()) {
                this.search.year = this.enrolment_years[i].year;
                break;
              }
            }

          })
    },
    getCandidateSummary(examKey){
      this.$router.push({
        name: 'candidate-summary',
        params: {examKey: examKey}
      })
    },
  },
  mounted() {
    this.getAllSalesReport();
    this.getAllScore();
    this.getAllPurchaseTypes();
    this.getAllEnrolmentYears();
  },
  computed:{
    currentUser() {
      return this.$store.getters.currentUser;
    },
    appEnv() {
      return process.env.VUE_APP_ENV;
    },
  },
  watch:{
    userSearch(oldVal, newVal) {      
      if(oldVal == newVal){
        return false;
      }

      this.users = [];
      let data = {
        name:newVal,
        access_type: this.search.received_by_type == 'local' ? 'score' : 'federal',
        score_id: null
      };

      adminUser
      .search(data)
      .then((response) => {
          response.data.adminUsers.map((user) => {
              let data = user;
              data.display_text = user.full_name+'('+user.email+')' ;
              this.users.push(data);
          });
      })
      .catch((err) => {

      })
      .finally(() => (this.userLoading = false));
    },
  }
}
</script>